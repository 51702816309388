import React, { useState, useRef } from "react";

import { Layout, SEO } from "../../../components/structure";
import { Content, Hero } from "../../../components/blocks";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import HospitalityRequestDemoForm from "../../../components/structure/HospitalityRequestDemoForm";
import IconTextBlock from "../../../components/blocks/icon-block/IconTextBlock";
import PhoneInput from "react-phone-number-input";

const RequestADemoPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "GettyImages-1172128727.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right.png" }) {
        ...SubmitButton
      }
      rocketIcon: file(base: { eq: "RocketIcon.png" }) {
        childImageSharp {
          fixed(width: 44, height: 44, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      buildingIcon: file(base: { eq: "BuildingIcon.png" }) {
        childImageSharp {
          fixed(width: 45, height: 41, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      walletIcon: file(base: { eq: "WalletIcon.png" }) {
        childImageSharp {
          fixed(width: 45, height: 37, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const ContentContainer = styled.div`
    ${font.imports.sherika}

    width: 95%;
    display: grid;
    grid-template-columns: 0.55fr 0.45fr;
    grid-template-rows: 0.1fr 0.9fr;
    grid-template-areas:
      "title form"
      "benefits form";
    align-items: flex-start;
    justify-content: space-between;
    padding: 30px 0px 90px 0px;
    max-width: 1400px;
    margin:auto;

    @media only screen and ${mq.maxMd} {
      display: grid;
      grid-template-areas:
        "title"
        "form"
        "benefits";
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      margin: 20px;
      justify-items: center;
      padding: 10px 0px;
    }

    .copy-content {
      height: 100%;
      grid-area: title;

      /* @media only screen and ${mq.maxMd} {
        padding: 20px;
      } */

      h2 {
        padding-top: 30px;
        font-family: ${font.family.sherika};
        font-weight: 634;
        font-size: 48px;
        margin-bottom: 25px;
        color: ${brand.colors.pg_primary_dark};

        @media only screen and ${mq.maxMd} {
          font-size: 30px;
          padding-top: 20px;

        }
      }
    }

    .benefit-container {
      grid-area: benefits;

      p {
        font-family: ${font.family.sherika};
        font-weight: 634;
        font-size: 30px;
        color: ${brand.colors.pg_blue};
        line-height: 45px;

        @media only screen and ${mq.maxMd} {
          font-size: 20px;
      justify-items: center;

        }
      }

      .icon-container {
        display: grid;
       grid-template-columns: auto auto auto;
        justify-content: space-between;
        margin-top: 80px;
        width: 90%;

        @media only screen and ${mq.maxMd} {
          margin: 0px 20px 20px 0px;
         grid-template-columns: none;
         gap: 20px;
         grid-template-rows: auto auto auto;
         justify-content: center;
        }
      }
    }

    .form-content {
      margin: 20px 0px;
      justify-self: center;
      grid-area: form;
      align-self: flex-start;

      @media only screen and ${mq.maxMd} {
        margin: 0 20px 20px 0;
      }

      .form-con {
        max-width: 530px;
        background-color: #fff;
        padding: 30px;

        /* @media only screen and ${mq.maxMd} {
          padding: 20px 20px;
        } */
      }

      .thank-you-message {
        font-family: ${font.family.sherika};
        background-color: ${brand.colors.pg_blue};
        font-weight: 600;
        font-size: 56px;
        width: 100%;
        min-height: 750px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 100px 90px;
        margin-bottom: 0;
        @media only screen and ${mq.maxMd} {
          font-size: 36px;
          word-wrap: initial;
        }
      }
    }
  `;

  return (
    <Layout>
      <SEO
        title="Hospitality | Plusgrade"
        description={
          "See our globally trusted ancillary revenue products in action."
        }
        keywords={"demo, trial"}
      />

      <Content secondaryLightBG noSpace>
        <ContentContainer>
          <div className="copy-content">
            <h2>Increase your revenue today</h2>
          </div>
          <div className="benefit-container">
            <p>
              Plusgrade's hotel upselling platform redefines the guest
              experience with innovative ancillary revenue solutions proven to:
            </p>
            <div className="icon-container">
              <IconTextBlock
                icon={data["rocketIcon"]}
                text={"Elevate guest satisfaction"}
              ></IconTextBlock>
              <IconTextBlock
                icon={data["walletIcon"]}
                text={"Drive incremental revenue"}
              ></IconTextBlock>
              <IconTextBlock
                icon={data["buildingIcon"]}
                text={"Optimize yield on guest room inventory"}
              ></IconTextBlock>
            </div>
          </div>
          <div className="form-content">
            {location.search.indexOf("submitted") === -1 ? (
              <div className="form-con">
                <HospitalityRequestDemoForm returnURL={location.href} />
              </div>
            ) : (
              <h2 className="thank-you-message">
                Thank you! Your request has been submitted, and a member of our
                sales team will be in touch.
              </h2>
            )}
          </div>
        </ContentContainer>
      </Content>
    </Layout>
  );
};

export default RequestADemoPage;
